import React from "react";
import "../index.scss";
import { Link } from "react-router-dom";

function EventBanner() {
  return (
    <div className="event-banner relative h-auto pb-[15rem] py-[4rem] w-[100%]  flex flex-col items-center justify-center ">
      <div className="w-[90%] md:w-[650px] text-center mx-auto">
        <h1 className="text-[1.7rem] xm:text-[3rem] text-[white] font-montserrat font-[600]">
          Eventet
        </h1>
        <h1 className="text-[2rem] xm:text-[3rem] text-[orangered] font-montserrat font-[700] italic">
          Mountain Bike 06
        </h1>
        <p className="text-[1rem] xm:text-[1.4rem] text-[white] font-montserrat font-[400] pt-[8px]">
          {/* "Challenge yourself and compete against the best in our thrilling downhill race competition." */}
          "Klubi ynë ka synim që në vazhdimësi të organizoj evente dhe gara të
          ndryshme"
        </p>
        <Link to={"/eventet"}>
          <h1 className="button w-[170px] mx-auto my-[2.5rem] py-[0.6rem] rounded-[5px] text-[white] bg-[orangered] duration-200 border-2 border-[orangered] font-[500] text-[17px]">
            Mëso më shumë
          </h1>
        </Link>
      </div>
      <div className="absolute -bottom-[10rem] xm:-bottom-[7rem] mx-auto  w-[95%] h-auto  lg:w-[90%] xl:w-[1200px] flex items-center justify-center gap-[0rem] flex-wrap xm:flex-nowrap">
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/galeri/3.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/17.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/galeri/4.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default EventBanner;
