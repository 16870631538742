import React from "react";
import Navbar from "./Navbar";
import GoToTop from "./GoToTop";
import "../index.scss";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function RrethNesh() {
  return (
    <>
      <div className="events w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <div>
          <h1 className="text-[2.5rem] md:text-[3rem] text-center relative w-[170px] md:w-[200px] mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
            Eventet
            <div className="w-[8rem] h-[4px] bg-[orangered] absolute"></div>
          </h1>
        </div>
      </div>{" "}
      <Link to={"/downhill-cup"}>
        <div className="cup-banner rounded-[15px] duration-500 mx-auto w-[90%] md:w-[80%] py-[3rem] mt-[2rem] xm:mt-[4rem] text-center">
          <h1 className="text-[28px] xm:text-[30px] md:text-[40px] text-white py-[1rem] font-semibold">
            Gjilani Downhill Cup 2023
          </h1>
        </div>
      </Link>
      <div className="bg-[#c9c8c8f5] rounded-[15px] mx-auto w-[90%] md:w-[80%] py-[3rem] my-[2rem] xm:my-[4rem] text-center">
        <h1 className="text-[28px] xm:text-[30px] md:text-[40px] text-white py-[1rem] font-semibold">
          Coming soon...
        </h1>
      </div>
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[1.5rem] text-[black]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[1.5rem] text-[black]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default RrethNesh;
