import React, { useState } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import "../index.scss";

function Navbar() {
  const [navbar, setNavbar] = useState(false);
  const [menu, setMenu] = useState(false);

  const openMenu = () => {
    setMenu(true);
  };

  const closeMenu = () => {
    setMenu(false);
  };

  const changeNavColor = () => {
    if (window.scrollY >= 40) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeNavColor);

  return (
    <>
      <div
        className={
          navbar
            ? "fixed top-[0rem] z-[20] w-full shadow-lg text-[#202020] bg-[black] flex flex-col items-center justify-center "
            : "fixed top-[0rem] w-full text-white flex flex-col items-center justify-center z-[10] "
        }
      >
        <div className="nav w-[95%] lg:w-[90%] border-b-[1px] border-[orangered] py-[0.3rem] xl:w-[1200px]  flex items-center md:justify-center justify-between">
          <div className="w-[95px] block md:hidden">
            <img src={"/imgs/logo4.png"} className="w-full h-auto" alt="" />
            {/* <h1 className="absolute right-[33px] italic -bottom-[14px] text-[white] font-montserrat font-extrabold text-[1.5rem]">
                06
              </h1> */}
          </div>

          <ul className="hidden font-openSans text-[#fffdfd] md:flex space-x-5 lg:space-x-[3rem] text-[17px]  tracking-[1px] font-poppins items-center">
            <Link to={"/"}>
              <li className="hover:text-[orangered] duration-300">Kryefaqja</li>
            </Link>

            <Link to={"/info"}>
              <li className="hover:text-[orangered] duration-300">
                Rreth nesh
              </li>
            </Link>

            <Link to={"/"}>
              <div className="w-[95px] ">
                <img src={"/imgs/logo4.png"} className="w-full h-auto" alt="" />
              </div>
            </Link>

            <Link to={"/eventet"}>
              <li className="hover:text-[orangered] duration-300">Eventet</li>
            </Link>
            <Link to={"/galeria"}>
              <li className="hover:text-[orangered] duration-300">Galeria</li>
            </Link>
          </ul>
          <div className="flex gap-x-[10px] md:hidden">
            <div className="flex gap-x-[3px] xm:gap-x-[10px] pt-[13px] md:hidden"></div>

            <button
              onClick={openMenu}
              className="block md:hidden text-[orangered]"
            >
              <HiOutlineMenuAlt3 size="30px" />
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          menu
            ? "w-[18rem] sm:w-[23rem] bg-[#020202] md:hidden flex flex-col justify-between py-[2rem] fixed h-full z-20 top-0 left-0 pt-[2rem] duration-300"
            : "fixed  -top-full z-10 h-full w-1/2 bottom-0 ease-in-out duration-300 left-0"
        }
      >
        <div className="flex flex-col items-center h-full justify-between pl-[2rem]   ">
          <div className="w-full flex flex-col ">
            <div className="flex items-center w-full">
              <Link to={"/"}>
                <div className="w-[7rem] ">
                  <img src={"/imgs/logo4.png"} className="w-full" alt="" />
                </div>
              </Link>
              <div className="w-full  flex justify-end pr-[1rem]">
                <button
                  onClick={closeMenu}
                  className="block md:hidden text-[orangered] border-[2px] border-[orangered] rounded-full px-[5px]"
                >
                  <AiOutlineClose size="30px" />
                </button>
              </div>
            </div>
            <ul className="pt-[4rem] font-openSans text-[#f0ecec] pl-[1rem] font-[300] flex flex-col space-y-5 text-[1.3rem] xm:text-[1.5rem] tracking-[1px] font-poppins	">
              <Link to={"/"}>
                <li className="hover:text-[orangered] duration-300">
                  Kryefaqja
                </li>
              </Link>

              <Link to={"/info"}>
                <li className="hover:text-[orangered] duration-300">
                  Rreth nesh
                </li>
              </Link>

              <Link to={"/eventet"}>
                <li className="hover:text-[orangered] duration-300">Eventet</li>
              </Link>
              <Link to={"/galeria"}>
                <li className="hover:text-[orangered] duration-300">Galeria</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
