import "./index.scss";
import Main from "./components/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RrethNesh from "./components/RrethNesh";
import Gleria from "./components/Galeria";
import Evente from "./components/Evente";
import DownHill from "./components/DownHill";
import Foto from "./components/Foto";
import Video from "./components/Video";

function App() {
  return (
    <div className="App  w-full h-[100vh]">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Info" element={<RrethNesh />} />
          <Route path="/eventet" element={<Evente />} />
          <Route path="/Galeria" element={<Gleria />} />
          <Route path="/downhill-cup" element={<DownHill />} />
          <Route path="/foto" element={<Foto />} />
          <Route path="/video" element={<Video />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
